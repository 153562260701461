/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

textarea:focus,
input:focus {
  outline: none !important;
}

*:focus {
  outline: none !important;
}

.form-control:focus {
  border-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/* Importing Bootstrap SCSS file. */
@import './colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,700&display=swap');

body {
  margin: 0;
  font-family: Montserrat, 'Helvetica Neue', sans-serif !important;
  overflow: hidden;
  background-color: $color-background !important;
  a {
    text-decoration: none;
    color: $color-pink;
  }
}
select {
  -webkit-appearance: none;
}
.center-me {
  width: 50%;
  height: 50%;
  margin-left: 25%;
  margin-top: 25%;
}
.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.form-control {
  padding: 15px;
  border-color: #71706e !important;
  border-radius: 3px !important;
}

.center {
  padding: 15px;
  text-align: center;
}

body {
  background-color: #f8f7f3;
  font-size: 10px !important;
  margin: 0;
  padding: 0;
}

.btn-primary {
  width: 266px;
  height: 62px;
  background-color: #fa7f76 !important;
  border-radius: 1px !important;
  border-color: #fa7f76 !important;
  p {
    margin: 0 !important;
  }
}

.modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  min-height: calc(100vh - 60px);
  border-radius: 18px;
}
.centered {
  display: block;
  margin: 0 10% !important;
}

.centered-wide {
  display: block;
  margin: 0 1% !important;
}

@media (max-width: 767px) {
  .modal-dialog {
    min-height: calc(100vh - 20px);
  }
  .centered {
    display: block;
    margin: 0 0 !important;
  }
}

.text-peach {
  color: $color-peach;
}

.title1 {
  color: $color-peach;
  text-align: center;
}

// .title2 {
//   text-align: center;
//   font-size: 35px;
//   font-weight: 700;
//   line-height: 70px;
// }

// .title3 {
//   text-align: center;
//   font-size: 20px;
//   line-height: 25px;
//   font-weight: 100;
//   font-style: normal;
//   color: #000;
// }

// .title4 {
//   text-align: center;
//   font-size: 0.7rem !important;
// }

input[type='radio'] {
  -webkit-appearance: none;
  background: #fff !important;
  border: 1px solid #686153;
  outline: none;
}

input[type='radio']:hover {
  border: 1px solid $color-peach;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
input[type='radio']:checked:before {
  background: $color-peach;
}

input[type='checkbox']:checked {
  background: $color-peach;
}
.btn-lg {
  line-height: 40px !important;
  border-radius: 2px !important;
}

@media only screen and (max-width: 2000px) {
  .title1 {
    text-align: center;
    font-size: 3rem;
    font-weight: 700;
    margin: 10 0 0 0;
  }

  .title2 {
    text-align: center;
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 40px;
  }

  .title3 {
    text-align: center;
    font-size: 1.8rem;
    line-height: 2rem;
    font-weight: 300;
    color: #000;
  }
}

@media only screen and (max-width: 600px) {
  .panel {
    margin-top: 15px !important;
    padding-right: 5%;
    padding-left: 5%;
  }
  .title1 {
    text-align: center;
    font-size: 35px;
    line-height: 25px;
    margin-right: 5% !important;
    margin-left: 5% !important;
    margin-top: 0 !important;
    margin-bottom: 20px !important;
  }

  .title2 {
    text-align: center;
    font-size: 20px;
    line-height: 35px;
    margin-right: 5% !important;
    margin-left: 5% !important;
    margin-top: 0 !important;
    margin-bottom: 20px !important;
  }

  .title3 {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    margin-right: 10% !important;
    margin-left: 10% !important;
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }

  .step-frame {
    padding: 3px !important;
  }
}
