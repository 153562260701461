@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?3ozrrw');
  src:  url('fonts/icomoon.eot?3ozrrw#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?3ozrrw') format('truetype'),
    url('fonts/icomoon.woff?3ozrrw') format('woff'),
    url('fonts/icomoon.svg?3ozrrw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check:before {
  content: "\f00c";
}
.icon-close:before {
  content: "\f00d";
}
.icon-remove:before {
  content: "\f00d";
}
.icon-times:before {
  content: "\f00d";
}
.icon-search-plus:before {
  content: "\f00e";
}
.icon-search-minus:before {
  content: "\f010";
}
.icon-home:before {
  content: "\f015";
}
.icon-repeat:before {
  content: "\f01e";
}
.icon-rotate-right:before {
  content: "\f01e";
}
.icon-refresh:before {
  content: "\f021";
}
.icon-flag1:before {
  content: "\f024";
}
.icon-pencil:before {
  content: "\f040";
}
.icon-edit:before {
  content: "\f044";
}
.icon-pencil-square-o:before {
  content: "\f044";
}
.icon-arrows:before {
  content: "\f047";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-exclamation-circle:before {
  content: "\f06a";
}
.icon-eye:before {
  content: "\f06e";
}
.icon-eye-slash:before {
  content: "\f070";
}
.icon-chain:before {
  content: "\f0c1";
}
.icon-link:before {
  content: "\f0c1";
}
.icon-chart:before {
  content: "\e954";
}
.icon-cone:before {
  content: "\e957";
}
.icon-alarm:before {
  content: "\e900";
}
.icon-flag:before {
  content: "\e95e";
}
.icon-list2:before {
  content: "\e959";
}
.icon-grid:before {
  content: "\e95b";
}
.icon-target1:before {
  content: "\e95c";
}
